
import { WeddingApi } from '@/api/WeddingApi';
import BotWeddingEventForm from '@/components/bot/wedding/BotWeddingEventForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { WeddingEventSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { WeddingEvent } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'bot-wedding-event-settings-card',
  components: {
    BotWeddingEventForm,
  },
})
export default class BotWeddingEventSettingsCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly event!: WeddingEvent;


  weddingEventSettings: WeddingEventSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.weddingEventSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      const botId = this.$route.params.id;
      await WeddingApi.updateBotWeddingEvent(botId, this.event.id, this.weddingEventSettings);
      this.$notify.success('Successfully updated settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('event', { immediate: true, deep: true })
  private reset() {
    this.weddingEventSettings = this.resetValue();
  }

  private resetValue(): WeddingEventSettings {
    return {
      name: this.event.name,
      title: this.event.title,
      description: this.event.description,
      slug: this.event.slug,
      styles: this.event.styles,
      date: this.event.date
    };
  }
}
