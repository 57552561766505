
import BotAgentCreateModal from '@/components/bot/BotAgentCreateModal.vue';
import AppColorPicker from '@/components/ui/AppColorPicker.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { WeddingEventSettings } from '@/types';
import QRCode from 'qrcode';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';


@Component({
  name: 'bot-wedding-event-form',
  components: { AppColorPicker, BotAgentCreateModal },
})
export default class BotWeddingEventForm extends mixins(AuthMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: WeddingEventSettings;

  @Prop({ required: true, type: Boolean, default: false })
  readonly create!: boolean;

  qrCodeDataUrl = '';
  showCreateAgentModal = false;



  onInput(key: string, value: any) {
    const updatedValue: WeddingEventSettings = JSON.parse(JSON.stringify(this.value));
    

    if (key.includes('.')) {
      const keys = key.split('.');
      let current: any = updatedValue;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!(keys[i] in current)) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
    } else {
      (updatedValue as any)[key] = value;
    }

    this.$emit('input', updatedValue);
  }

  get publicUrl() {
    return `/wedding/${this.value.slug}`;
  }



  async mounted() {
    try {
      this.qrCodeDataUrl = await QRCode.toDataURL(this.publicUrl);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  }



  generateSlugFromName(name: string) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  @Watch('value.name')
  onNameChange(newName: string): void {
    this.onInput('slug', this.generateSlugFromName(newName));
  }
}
