
import { S3_BUCKET, S3_REGION } from '@/api';
import { WeddingApi } from '@/api/WeddingApi';
import AppMultiFileDropzone from '@/components/ui/AppMultiFileDropzone.vue';
import { WeddingEvent, WeddingEventImage } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
  name: 'bot-wedding-event-images-card',
  components: {
    AppMultiFileDropzone,
    draggable,
  },
})
export default class BotWeddingEventImagesCard extends Vue {
  @Prop({ required: true, type: Object })
  readonly event!: WeddingEvent;

  drag = false;
  loadingApproveIds: Record<string, boolean> = {}; // Track approve loading state
  loadingDeleteIds: Record<string, boolean> = {}; // Track delete loading state
  publishedImages: WeddingEventImage[] = []; // Add this local array to store published images

  fileUploaded(file: any, response: any) {
    this.$emit('images-uploaded');
  }

  imageUrl(image: WeddingEventImage) {
    return `https://${S3_BUCKET}.s3.${S3_REGION}.amazonaws.com/bots/${this.$route.params.id}/wedding/events/${this.event.id}/images/${image.id}.${image.extension}`;
  }

  mounted() {
    this.loadingDeleteIds = this.event.images.reduce((acc, image) => {
      acc[image.id] = false;
      return acc;
    }, {} as Record<string, boolean>);

    this.loadingApproveIds = this.event.images.reduce((acc, image) => {
      acc[image.id] = false;
      return acc;
    }, {} as Record<string, boolean>);
    
    // Initialize the published images array
    this.publishedImages = this.event.images.filter(image => image.state === 'published');
  }

  async deleteImage(image: WeddingEventImage) {
    try {
      this.loadingDeleteIds[image.id] = true;
      await WeddingApi.deleteBotWeddingEventImage(this.$route.params.id, this.event.id, image.id);
      this.$emit('image-deleted');
    } catch (error) {
      console.error('Error deleting image', error);
    } finally {
      this.loadingDeleteIds[image.id] = false;
    }
  }

  async updateImagesOrder() {
    try {
      const updatedOrder = this.publishedImages.map((image, index) => ({
        id: image.id,
        order: index,
      }));
      
      await WeddingApi.updateBotWeddingEventImagesOrder(
        this.$route.params.id,
        this.event.id,
        updatedOrder
      );
      this.$emit('images-reordered');
    } catch (error) {
      console.error('Error updating image order ', error);
    }
  }

  async toggleHeroImage(image: WeddingEventImage) {
    try {
      await WeddingApi.updateImageHeroStatus(
        this.$route.params.id,
        this.event.id,
        image.id,
        !image.heroImage
      );
      // Update the local state
      image.heroImage = !image.heroImage;
      // If this image is now the hero, set all others to false
      if (image.heroImage) {
        this.event.images.forEach(img => {
          if (img.id !== image.id) {
            img.heroImage = false;
          }
        });
      }
      this.$emit('hero-image-updated');
    } catch (error) {
      console.error('Error updating hero image status', error);
    }
  }

  async approveImage(image: WeddingEventImage) {
    try {
      this.loadingApproveIds[image.id] = true;
      await WeddingApi.updateImageState(
        this.$route.params.id,
        this.event.id,
        image.id,
        'published'
      );
      this.$emit('image-approved');
    } catch (error) {
      console.error('Error updating hero image status', error);
    } finally {
      this.loadingApproveIds[image.id] = false;
    }
  }
  


  get inReviewImages() {
    return this.event.images.filter(image => image.state === 'in_review');
  }

  get draftImages() {
    return this.event.images.filter(image => image.state === 'draft');
  }
}
