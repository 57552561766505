
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { AuthMixin } from '@/mixins/AuthMixin';
import { AppRoute } from '@/router/routes';
import { WeddingEvent, WlcmHomeCampaign } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
  import BotWeddingEventSettingsCard from '@/components/bot/wedding/BotWeddingEventSettingsCard.vue';
import BotWeddingEventImagesCard from '@/components/bot/wedding/BotWeddingEventImagesCard.vue';
import { WeddingApi } from '@/api/WeddingApi';
@Component({
  name: 'project-wedding-event-overview',
  components: { BotWeddingEventSettingsCard, BotWeddingEventImagesCard },
})
export default class ProjectWeddingEventOverview extends mixins(AuthMixin) {

  event: WeddingEvent | null = null;

  isDeleting = false;
  async mounted() {

    await this.loadEvent();
  }

  async loadEvent() {
   try {
         this.event = await WeddingApi.getBotWeddingEvent(this.$route.params.id, this.$route.params.eventId);

    } catch (error) {
      console.error("Can't load event", error);
    }
  }
  async requestDeleteConfirmation() {
    if (!this.event) return;
    const confirmed = confirm(`Are you sure that you want to delete this event?`);
    if (confirmed) {
      this.isDeleting = true;
      try {
        this.$notify.success('Successfully deleted event');
        await WeddingApi.deleteBotWeddingEvent(this.$route.params.id, this.$route.params.eventId);
        await this.$router.replace({ name: AppRoute.ProjectWeddingEvents });
      } catch (e) {
        this.$notify.error({
          title: 'Deleting the event failed',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      } finally {
        this.isDeleting = false;
      }
    }
  }

}
